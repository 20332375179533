/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

* {
	font-family: Montserrat, sans-serif !important;
	padding: 0;
	margin: 0;
}

:root {
	--fc-button-text-color: #ffffff;
	--fc-button-bg-color: #129bdb;
	--fc-button-border-color: #129bdb;
	--fc-button-hover-bg-color: #129bdb;
	--fc-button-hover-border-color: #129bdb;
	--fc-button-active-bg-color: #129bdb;
	--fc-button-active-border-color: #129bdb;
}

.MuiPopover-root {
	z-index: 100000 !important;
}
